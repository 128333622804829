import { render, staticRenderFns } from "./Setting_record.vue?vue&type=template&id=38732927&scoped=true&"
import script from "./Setting_record.vue?vue&type=script&lang=js&"
export * from "./Setting_record.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38732927",
  null
  
)

export default component.exports