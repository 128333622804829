<template>
	<div>
		<SettingRecordView :type_id="type_id"></SettingRecordView>
	</div>
</template>

<script>
	import SettingRecordView from '@/components/Page/FeeSettingRecordView.vue'
	export default {
		components: {
      SettingRecordView
		},
		data() {
			return {
        type_id: 0
			}
		},
		created() {
      this.type_id = this.$route.query.type_id
		},
		methods: {

		},
	}
</script>

<style lang="scss" scoped>

</style>